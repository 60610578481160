import Vue from 'vue';
import App from './App.vue';
import axios from 'axios';
import router from './router';
import store from './store';
import './assets/scss/main.scss';
import shareIt from 'vue-share-it';

Vue.config.productionTip = false;

Vue.use(shareIt);

Vue.prototype.$eventBus = new Vue;
Vue.prototype.$authRetry = true;
Vue.prototype.$stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_API_KEY);

store.$axios = axios;
store.$urlSearchParams = new URLSearchParams(window.location.search);

store.dispatch('getMeta').then(() => {
  new Vue({
    router,
    store,
    render: h => h(App),
    created() {
      this.$store.dispatch('setTimezone')
    }
  }).$mount('#app');
});

