<template>
  <iframe :src="url" style="display:none" ref="frame"></iframe>
</template>
<script>
export default {
  data () {
    return {
      url: `${process.env.VUE_APP_AUTH_BASE_URL}/auth/sso-token`
    }
  },
  mounted () {
    this.$refs.frame.addEventListener('load', () => {
      this.$refs.frame.contentWindow.postMessage({
        action: 'get',
        key: process.env.VUE_APP_SSO_TOKEN_VAR
      }, process.env.VUE_APP_AUTH_BASE_URL)
    })
    window.addEventListener('message', event => {
      if (typeof event.data.action !== 'undefined') {
        if (event.data.action === 'sso-token-response') {
          this.$emit('SSO_TOKEN', event.data.value)
        }
        if (event.data.action === 'sso-impersonation-token') {
          this.$emit('SSO_IS_IMPERSONATING', event.data.value)
        }
      }
    }, false)
  },
  methods: {
    setSSOToken () {
      if (!this.$store.getters.hasToken)
        return
      this.$refs.frame.contentWindow.postMessage({
        action: 'set',
        key: process.env.VUE_APP_SSO_TOKEN_VAR,
        value: this.$store.state.auth.token
      }, process.env.VUE_APP_AUTH_BASE_URL)
    },
    refresh () {
      //console.log('REFRESH TOKEN')
      this.$refs.frame.contentWindow.postMessage({
        action: 'reload'
      }, process.env.VUE_APP_AUTH_BASE_URL)
    }
  }
}
</script>
