<template>
  <div>
    <modal
        ref="enrol"
        :classes="['small']"
    >
      <section class="enrol">
        <header>
          <h2 class="text-xl">
            Enrol in class <b>{{ lesson.label }}</b>
          </h2>
        </header>
        <main>
          <div v-if="this.$store.getters.isLoggedIn && !registrationResponse">
            <h4 class="block text-blue-dark font-bold border-b border-blue-lighter pb-3 w-full mb-3">
              Enrolment Summary
            </h4>
            <div>
              <p class="mb-1">
                <b>Term:</b> {{ $store.state.term.label }} {{ $store.state.term.date }}
              </p>
              <p class="mb-1">
                <b>Course type:</b> {{ lesson.course_type.category.label }} - {{ lesson.course_type.label }}
              </p>
              <p class="mb-1">
                <b>Class time{{ this.additional.length ? 's' : '' }}:</b> {{ classTimes }}
              </p>
              <p class="mb-2">
                <b>Location:</b>
                <span v-if="lesson.is_online">
                  Online
                </span>
                <span v-else v-html="lesson.location.address"></span>
              </p>
            </div>
          </div>
          <div v-else>
            <h4 class="block text-blue-dark font-bold pb-3 w-full mb-3 text-xl">
              Do you have a My Lingopont account?
            </h4>
            <div class="flex flex-col lg:flex-row lg:-mx-4">
              <div class="flex flex-1 lg:px-4 mb-6 lg:mb-0">
                <a
                    class="button w-full hvr-push"
                    :class="{'active pointer-events-none': method === 'login'}"
                    @click.prevent="action('login')"
                >
                  Yes, I have an account
                </a>
              </div>
              <div class="flex flex-1 lg:px-4">
                <a
                    class="button w-full hvr-push"
                    :class="{'active pointer-events-none': method === 'register'}"
                    @click.prevent="action('register')"
                >
                  No, I don't yet have an account
                </a>
              </div>
            </div>
          </div>
          <div v-if="!$store.getters.isLoggedIn && method === 'login'" class="pt-8">
            <h4 class="block text-blue-dark font-bold border-b border-blue-lighter pb-3 w-full mb-4">
              Please login to continue
            </h4>
            <form
                class="flex flex-col"
                @submit.prevent="login"
            >
              <div class="mb-4">
                <label class="block font-bold text-lg mb-1">Email</label>
                <input
                    type="email"
                    placeholder="Please enter your email address"
                    class="px-3 py-3 placeholder-gray-400 text-gray-700 relative bg-white rounded text-sm border border-gray-400 outline-none w-full"
                    v-model="loginForm.email"
                    required
                />
              </div>
              <div class="mb-6">
                <label class="block font-bold text-lg mb-1">Password</label>
                <input
                    type="password"
                    placeholder="Please enter your password"
                    class="px-3 py-3 placeholder-gray-400 text-gray-700 relative bg-white rounded text-sm border border-gray-400 outline-none w-full"
                    v-model="loginForm.password"
                    required
                />
              </div>
              <div>
                <button type="submit" class="button inverted">
                  Login
                </button>
              </div>
            </form>
          </div>
          <div v-if="!$store.getters.isLoggedIn && method === 'register'" class="pt-8">
            <h4 class="block text-blue-dark font-bold border-b border-blue-lighter pb-3 w-full mb-4">
              Your details
            </h4>
            <form
                class="flex flex-col"
                @submit.prevent="register"
            >
              <div class="flex flex-col lg:flex-row mb-4 lg:-mx-4">
                <div class="lg:px-4 flex-1 mb-4 lg:mb-0">
                  <label class="block font-bold text-lg mb-1">First name</label>
                  <input
                      type="text"
                      placeholder="Please enter your first name"
                      class="px-3 py-3 placeholder-gray-400 text-gray-700 relative bg-white rounded text-sm border border-gray-400 outline-none w-full"
                      :class="{'error': hasRegistrationError('first_name')}"
                      v-model="registerForm.first_name"
                  />
                  <p class="input-error" v-if="hasRegistrationError('first_name')">
                    {{ registrationErrorMessage('first_name') }}
                  </p>
                </div>
                <div class="lg:px-4 flex-1">
                  <label class="block font-bold text-lg mb-1">Last name</label>
                  <input
                      type="text"
                      placeholder="Please enter your last name"
                      class="px-3 py-3 placeholder-gray-400 text-gray-700 relative bg-white rounded text-sm border border-gray-400 outline-none w-full"
                      :class="{'error': hasRegistrationError('sur_name')}"
                      v-model="registerForm.sur_name"
                  />
                  <p class="input-error" v-if="hasRegistrationError('sur_name')">
                    {{ registrationErrorMessage('sur_name') }}
                  </p>
                </div>
              </div>
              <div class="mb-4">
                <label class="block font-bold text-lg mb-1">Email</label>
                <input
                    type="email"
                    placeholder="Please enter your email address"
                    class="px-3 py-3 placeholder-gray-400 text-gray-700 relative bg-white rounded text-sm border border-gray-400 outline-none w-full"
                    :class="{'error': hasRegistrationError('first_name')}"
                    v-model="registerForm.email"
                />
                <p class="input-error" v-if="hasRegistrationError('email')">
                  {{ registrationErrorMessage('email') }}
                </p>
              </div>
              <div class="mb-4">
                <label class="block font-bold text-lg mb-1">Mobile</label>
                <input
                    type="text"
                    placeholder="Please enter your mobile number"
                    class="px-3 py-3 placeholder-gray-400 text-gray-700 relative bg-white rounded text-sm border border-gray-400 outline-none w-full"
                    :class="{'error': hasRegistrationError('mobile')}"
                    v-model="registerForm.mobile"
                />
                <p class="input-error" v-if="hasRegistrationError('mobile')">
                  {{ registrationErrorMessage('mobile') }}
                </p>
              </div>
              <div class="mb-4">
                <label class="block font-bold text-lg mb-1">How did you find us?</label>
                <select v-model="registerForm.how_did_you_find_us"
                        class="px-3 py-3 placeholder-gray-400 text-gray-700 relative bg-white rounded text-sm border border-gray-400 outline-none w-full">
                  <option disabled selected :value="null">
                    Please select an option
                  </option>
                  <option v-for="option in referral_options" :value="option">
                    {{ option }}
                  </option>
                </select>
                <p class="input-error" v-if="hasRegistrationError('how_did_you_find_us')">
                  {{ registrationErrorMessage('how_did_you_find_us') }}
                </p>
              </div>
              <div class="mb-4" v-if="registerForm.how_did_you_find_us === 'Other'">
                <label class="block font-bold text-lg mb-1">Please tell us how you found us</label>
                <input
                    type="text"
                    placeholder="Please enter a short description of how you found us"
                    class="px-3 py-3 placeholder-gray-400 text-gray-700 relative bg-white rounded text-sm border border-gray-400 outline-none w-full"
                    :class="{'error': hasRegistrationError('how_did_you_find_us_other')}"
                    v-model="registerForm.how_did_you_find_us_other"
                />
                <p class="input-error" v-if="hasRegistrationError('how_did_you_find_us_other')">
                  {{ registrationErrorMessage('how_did_you_find_us_other') }}
                </p>
              </div>
              <h4 class="block text-blue-dark font-bold border-b border-blue-lighter pb-3 w-full mt-4 mb-4">
                Billing address
              </h4>
              <div class="mb-4">
                <label class="block font-bold text-lg mb-1">Street address</label>
                <input
                    type="text"
                    placeholder="Please enter your street address"
                    class="px-3 py-3 placeholder-gray-400 text-gray-700 relative bg-white rounded text-sm border border-gray-400 outline-none w-full"
                    :class="{'error': hasRegistrationError('address_line_1')}"
                    v-model="registerForm.address_line_1"
                />
                <p class="input-error" v-if="hasRegistrationError('address_line_1')">
                  {{ registrationErrorMessage('address_line_1') }}
                </p>
              </div>
              <div class="mb-4">
                <label class="block font-bold text-lg mb-1">Street address (continued)</label>
                <input
                    type="text"
                    placeholder="Street address continued (if required)"
                    class="px-3 py-3 placeholder-gray-400 text-gray-700 relative bg-white rounded text-sm border border-gray-400 outline-none w-full"
                    :class="{'error': hasRegistrationError('address_line_2')}"
                    v-model="registerForm.address_line_2"
                />
                <p class="input-error" v-if="hasRegistrationError('address_line_2')">
                  {{ registrationErrorMessage('address_line_2') }}
                </p>
              </div>
              <div class="flex flex-col lg:flex-row mb-4 lg:-mx-4">
                <div class="lg:px-4 flex-1 mb-4 lg:mb-0">
                  <label class="block font-bold text-lg mb-1">Suburb</label>
                  <input
                      type="text"
                      placeholder="Please enter your suburb/city"
                      class="px-3 py-3 placeholder-gray-400 text-gray-700 relative bg-white rounded text-sm border border-gray-400 outline-none w-full"
                      :class="{'error': hasRegistrationError('suburb')}"
                      v-model="registerForm.suburb"
                  />
                  <p class="input-error" v-if="hasRegistrationError('suburb')">
                    {{ registrationErrorMessage('suburb') }}
                  </p>
                </div>
                <div class="lg:px-4 flex-1">
                  <label class="block font-bold text-lg mb-1">State</label>
                  <select v-model="registerForm.state"
                          class="px-3 py-3 placeholder-gray-400 text-gray-700 relative bg-white rounded text-sm border border-gray-400 outline-none w-full">
                    <option disabled selected :value="null">
                      Please select your state
                    </option>
                    <option v-for="state in states" :value="state.value">
                      {{ state.label }}
                    </option>
                  </select>
                  <p class="input-error" v-if="hasRegistrationError('state')">
                    {{ registrationErrorMessage('state') }}
                  </p>
                </div>
              </div>
              <div class="flex flex-col lg:flex-row mb-4 lg:-mx-4">
                <div class="lg:px-4 flex-1 mb-4 lg:mb-0">
                  <label class="block font-bold text-lg mb-1">Postcode</label>
                  <input
                      type="text"
                      placeholder="Please enter your postal code"
                      class="px-3 py-3 placeholder-gray-400 text-gray-700 relative bg-white rounded text-sm border border-gray-400 outline-none w-full"
                      :class="{'error': hasRegistrationError('postal_code')}"
                      v-model="registerForm.postal_code"
                  />
                  <p class="input-error" v-if="hasRegistrationError('postal_code')">
                    {{ registrationErrorMessage('postal_code') }}
                  </p>
                </div>
                <div class="lg:px-4 flex-1">
                  <label class="inline-block mt-8">
                    <input type="checkbox" class="relative top-1 mr-1" v-model="registerForm.use_as_shipping"
                           value="1"/>
                    <span class="relative" style="top: -2px">
                      Also use as shipping address?
                    </span>
                  </label>
                  <p class="input-error" v-if="hasRegistrationError('use_as_shipping')">
                    {{ registrationErrorMessage('use_as_shipping') }}
                  </p>
                </div>
              </div>
              <h4 class="block text-blue-dark font-bold border-b border-blue-lighter pb-3 w-full mt-4 mb-4">
                Password
              </h4>
              <div class="flex mb-6 -mx-4">
                <div class="px-4 flex-1">
                  <label class="block font-bold text-lg mb-1">Password</label>
                  <input
                      type="password"
                      placeholder="Please enter your password"
                      class="px-3 py-3 placeholder-gray-400 text-gray-700 relative bg-white rounded text-sm border border-gray-400 outline-none w-full"
                      :class="{'error': hasRegistrationError('password')}"
                      v-model="registerForm.password"
                  />
                  <p class="input-error" v-if="hasRegistrationError('password')">
                    {{ registrationErrorMessage('password') }}
                  </p>
                </div>
                <div class="px-4 flex-1">
                  <label class="block font-bold text-lg mb-1">Confirm password</label>
                  <input
                      type="password"
                      placeholder="Please confirm your password"
                      class="px-3 py-3 placeholder-gray-400 text-gray-700 relative bg-white rounded text-sm border border-gray-400 outline-none w-full"
                      :class="{'error': hasRegistrationError('password_confirmation')}"
                      v-model="registerForm.password_confirmation"
                  />
                  <p class="input-error" v-if="hasRegistrationError('password_confirmation')">
                    {{ registrationErrorMessage('password_confirmation') }}
                  </p>
                </div>
              </div>
              <div>
                <button type="submit" class="button inverted">
                  Register
                </button>
              </div>
            </form>
          </div>
          <div v-if="$store.getters.isLoggedIn">
            <div class="mt-10" v-if="!$store.getters.isImpersonating && !$store.getters.isAdmin">
              <h4 class="block text-blue-dark font-bold border-b border-blue-lighter pb-3 w-full mb-3">
                Payment Details
              </h4>
              <p class="text-xl"><b>Enrolment fee:</b> {{ cost }}</p>
              <form class="payment-form my-4">
                <div ref="stripeCardElement" class="card-element"></div>
                <div :class="{'invisible': !cardError}" class="min-h-4">
                  {{ cardError }}
                </div>
              </form>
            </div>
            <div class="mt-8">
              <label>
                <input type="checkbox" required class="relative top-1 mr-1" v-model="terms_agreed"/>
                <span class="relative" style="top: -2px">
                  I have read and agree to the
                  <a
                      href="https://italianstudies.com.au/terms-and-conditions"
                      target="_blank"
                      class="text-blue hover:text-blue-dark"
                  >terms and conditions</a>.
                </span>
              </label>
            </div>
            <p class="flex justify-center mt-8">
              <button
                  class="button large alternate hvr-push"
                  ref="enrolButton"
                  @click.prevent="submit"
                  :disabled="disabled || !this.terms_agreed"
              >
                Enrol now
              </button>
            </p>
          </div>
        </main>
      </section>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal'
//import EnrolmentConfirmation from '@/components/EnrolmentConfirmation'

export default {
  components: {
    Modal,
    //EnrolmentConfirmation
  },
  props: {
    lesson: {
      type: Object,
      required: true
    },
    additional: {
      type: Array,
      required: false,
      default: []
    },
    shareUrl: {
      type: String,
      required: true
    }
  },
  mounted () {
    this.$on('open', this.init)
  },
  data () {
    return {
      method: null,
      cost: this.lesson.display_cost,
      paymentIntent: null,
      error: false,
      disabled: this.error,
      card: null,
      cardError: null,
      paymentMethod: null,
      terms_agreed: false,
      loginForm: {
        email: null,
        password: null
      },
      registerForm: {
        first_name: null,
        sur_name: null,
        email: null,
        address_line_1: null,
        address_line_2: null,
        suburb: null,
        state: null,
        postal_code: null,
        use_as_shipping: null,
        mobile: null,
        how_did_you_find_us: null,
        how_did_you_find_us_other: null,
        password: null,
        password_confirmation: null
      },
      registrationErrors: [],
      registrationResponse: null,
      states: [
        {
          label: 'New South Wales',
          value: 'NSW'
        },
        {
          label: 'Victoria',
          value: 'VIC'
        },
        {
          label: 'Queensland',
          value: 'QLD'
        },
        {
          label: 'Tasmania',
          value: 'TAS'
        },
        {
          label: 'South Australia',
          value: 'SA'
        },
        {
          label: 'Western Australia',
          value: 'WA'
        },
        {
          label: 'Northern Territory',
          value: 'NT'
        },
        {
          label: 'Australian Capital Territory',
          value: 'ACT'
        }
      ],
      referral_options: [
        'Google Ads',
        'Google Search',
        'CIS Social Media (FB or IG)',
        'Influencers promotion on Instagram',
        'General media (print, tv, radio)',
        'CIS Newsletter',
        'Word of mouth',
        'Other'
      ]
    }
  },
  methods: {
    action (method) {
      this.method = method
    },
    login () {
      this.$store.dispatch('login', this.loginForm)
          .then(() => this.init())
          .catch(() => {
            this.notifyError('Could not login with those credentials, please try again')
          })
    },
    register () {
      this.registrationErrors = []
      this.$store.dispatch('register', this.registerForm)
          .then(response => {
            this.registrationResponse = response.data
            if (!this.$store.getters.isLoggedIn && this.registrationResponse) {
              this.$store.commit('auth_token', this.registrationResponse.token)
              this.$store.commit('auth_user', this.registrationResponse.user)
              this.$store.dispatch('setupToken', this.registrationResponse.token)
              this.registrationResponse = null
            }
            this.init()
          })
          .catch(error => {
            this.notifyError('There was a problem with your submission')
            if (typeof error.response.data.errors !== 'undefined') {
              this.registrationErrors = error.response.data.errors
            }
          })
    },
    init () {
      if (!this.$store.getters.isLoggedIn || (this.paymentIntent || this.$store.getters.isImpersonating || this.$store.getters.isAdmin))
        return this.$refs.enrol.open = true

      this.$store.dispatch('checkAuth')
          .catch(() => {})
          .finally(() => {
            this.$store
                .dispatch('intent', this.lesson.id)
                .then(response => {
                  if (typeof response.data.cost !== 'undefined' && response.data.cost) {
                    this.cost = response.data.cost
                  }
                  this.paymentIntent = response.data.client_secret
                  this.stripeElements()
                  this.$refs.enrol.open = true
                })
                .catch(error => {
                  this.error = true
                  this.$refs.enrol.open = false
                  this.$notify({
                        group: 'errors',
                        title: 'Error',
                        text: 'An error has occurred, please reload the timetable application and try again',
                      }
                  )
                })
          })

    },
    stripeElements () {
      const elements = this.$stripe.elements()
      this.card = elements.create('card', {
        hidePostalCode: true,
        style: {
          base: {
            color: '#0069b9',
            fontFamily: 'Verdana, Geneva, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '14px',
            '::placeholder': {
              color: '#809fad'
            }
          },
          invalid: {
            fontFamily: 'Verdana, Geneva, sans-serif',
            color: '#9b2c2c',
            iconColor: '#9b2c2c'
          }
        }
      })
      this.card.mount(this.$refs.stripeCardElement)

      this.card.on('change', event => {
        this.disabled = event.empty || event.error || this.error
        this.cardError = event.error ? event.error.message : null
      })
    },
    submit () {
      if (!this.terms_agreed) {
        return this.notifyError('You must agree to the terms and conditions')
      }

      this.$store.commit('request_soft')
      this.enrol()
    },
    submitEnrolment (payload) {
      this.$store
          .dispatch('enrol', payload)
          .then(response => {
            this.$emit('enrolled', response.data)
          })
          .catch((error) => {
            this.notifyError('Unable to complete enrolment, please reload the timetable and try again')
          })
    },
    enrol () {
      let payload = {
        lesson_id: this.lesson.id
      }

      if (this.$store.getters.isImpersonating || this.$store.getters.isAdmin) {
        payload.impersonation_token = this.$store.state.auth.impersonating
        this.submitEnrolment(payload)
      } else {
        this.$stripe.confirmCardPayment(this.paymentIntent, {
          payment_method: {
            card: this.card
          }
        }).then(result => {

          if (result.error) {
            const message = typeof result.error.message !== 'undefined' && result.error.message
                ? result.error.message
                : 'Could not process your payment at this time, please contact support'
            this.$store.commit('request_error')
            return this.notifyError(message)
          }

          payload.payment_intent_id = result.paymentIntent.id
          this.submitEnrolment(payload)
        })
      }
    },
    notifyError (message) {
      this.$notify({
            group: 'errors',
            title: 'Error',
            text: message
          }
      )
    },
    hasRegistrationError (field) {
      return typeof this.registrationErrors[field] !== 'undefined'
    },
    registrationErrorMessage (field) {
      return this.registrationErrors[field][0]
    }
  },
  computed: {
    classTimes () {
      let times = []
      times.push(`Every ${this.lesson.day_name} ${this.lesson.display_time}`)

      if (this.additional.length) {
        this.additional.forEach(lesson => times.push(`Every ${lesson.day_name} ${lesson.display_time}`))
      }

      return times.join(', ')
    }
  }
}
</script>
