<template>
  <nav class="pt-2 pb-6 flex flex-col lg:flex-row justify-center lg:justify-start">
    <div class="flex justify-center mb-4 lg:mb-0 lg:mr-2" v-show="$store.getters.hasMoreTerms">
      <a
          class="icon-button hvr-push mr-4 lg:mr-2"
          v-if="$store.state.terms.previous"
          @click.prevent="go($store.state.terms.previous.id)"
      >
        <img src="@/assets/icons/arrow.svg" alt="Previous"/>
      </a>
      <a
          class="icon-button hvr-push"
          v-if="$store.state.terms.next"
          @click.prevent="go($store.state.terms.next.id)"
      >
        <img src="@/assets/icons/arrow.svg" alt="Next" class="transform rotate-180"/>
      </a>
    </div>
    <div
        class="flex-1 text-blue-light text-lg lg:text-xl font-light justify-center lg:justify-start text-center lg:text-left mb-4 lg:mb-0 font-bold"
        :class="{'lg:ml-3': $store.state.terms.previous || $store.state.terms.next}"
        v-if="$store.state.term"
    >
      <template v-if="$store.getters.hasRegion">
        <span class="font-bold text-blue inline-flex items-center">
          <span class="mr-2">{{
              typeof $store.state.region.heading_label === 'string'
                  ? $store.state.region.heading_label
                  : $store.state.region.label
            }}</span>
        </span>
        <span class="text-lg text-blue">{{ $store.state.region.gmt_offset_full_formatted }}</span>
        <br/>
      </template>
      {{ $store.state.term.label }}
      <span class="text-blue-another">{{ $store.state.term.date }}</span>
    </div>
    <div
        class="text-blue-light text-2xl leading-none -mt-1"
        v-else>
      <span>No terms currently available</span>
    </div>
  </nav>
</template>

<script>
import moment from 'moment';

export default {
  computed: {
    start() {
      return this.$store.state.term ?
          moment.utc(this.$store.state.term.start)
          : null;
    },
    stop() {
      return this.$store.state.term ?
          moment.utc(this.$store.state.term.stop)
          : null;
    },
    date() {
      const start = this.start.format(this.start.year() !== this.stop.year() ? 'D MMM YYYY' : 'D MMM'),
          stop = this.stop.format('D MMM YYYY');
      return `${start} - ${stop}`;
    },
  },
  methods: {
    go(termID) {
      this.$store.dispatch('getTerms', termID);
    },
  },
};
</script>
