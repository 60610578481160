import moment from 'moment';

export default {
  hasToken: state => !!state.auth.token,
  isLoggedIn: state => !!state.auth.token && !!state.auth.user.id,
  isImpersonating: state => !!state.auth.impersonating,
  isAdmin: state => !!state.auth.user.is_admin,
  getBanner: (state) => {
    if (!state.timetable) {
      return null;
    }
    return state.banners[state.timetable.key] || state.banners.default;
  },
  getLessonsByDayTime: (state, getters) => (day, time) => {
    if (!state.term) {
      return [];
    }

    const momentFormat = 'HH:mm:SS',
        afternoonSplitHour = 12,
        eveningSplitHour = 17;

    return state.term.lessons.filter(lesson => {
      const start = moment.utc(lesson.start_time, momentFormat),
          hour = parseFloat(start.format('HH'));

      let validTime = false;
      switch (time) {
        case 'morning':
          validTime = hour < afternoonSplitHour;
          break;
        case 'afternoon':
          validTime = hour >= afternoonSplitHour && hour < eveningSplitHour;
          break;
        case 'evening':
          validTime = hour >= eveningSplitHour;
          break;
      }

      return lesson.day === day + 1 && validTime;
    });
  },
  hasCourse: (state) => {
    if (!state.timetable) {
      return false;
    }
    return state.course.id !== null;
  },
  hasFilters: (state) => {
    for (const [key, value] of Object.entries(state.filtered)) {
      if (state.filtered[key].length) {
        return true;
      }
    }
    return false;
  },
  hasLessons: (state) => {
    if (!state.status || state.status === 'loading') {
      return true;
    }
    return !!state.term?.lessons.length;
  },
  hasRegion: (state) => !!state.region,
  hasRegions: (state) => !!(typeof state.regions !== 'undefined' && state.regions.length),
  isOnlineRegion: (state) => !!state.is_online_region,
  showLocalTimezone: (state) => !!(state.localTimeZone),
  hasMoreTerms: (state) => state.terms.previous || state.terms.next,
  shouldSelectRegion: (state, getters) => getters.hasRegions &&
      !getters.hasRegion,
  localTimeZoneForHumans: (state) => {
    const tz = state.localTimeZone;
    if (tz) {
      const operator = tz.current.offset < 0 ? '-' : '+';
      return `(UTC ${operator}${tz.current.offset})`;
    }
    return null;
  },
  enrolled: (state, getters) => (data) => {
    return getters.isLoggedIn
        ? state.auth.user.lessons.findIndex(userLesson => {
      return userLesson.timetable_lesson_id === data.lesson.id
          || (typeof userLesson.inclusive_lessons !== 'undefined' &&
              userLesson.inclusive_lessons.length &&
              userLesson.inclusive_lessons.findIndex(
                  inclusive => inclusive.timetable_lesson_id ===
                      data.lesson.id) !== -1)
          ||
          (typeof data.additional !== 'undefined' && data.additional.length &&
              data.additional.findIndex(
                  additional => additional.id === userLesson.id));
    }) !== -1
        : false;
  },
};
