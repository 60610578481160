<template>
  <div class="flex items-center justify-center">
    <a
        class="icon-button white"
        :class="{'invisible': $store.state.day <= 0}"
        @click.prevent="go($store.state.day-1)"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="10.875" height="9.739">
        <path fill="#0069b9"
              d="M.176 4.436L4.75.159A.58.58 0 015.547 1l-3.56 3.33h8.308a.58.58 0 110 1.16H2.074l3.473 3.247a.58.58 0 01-.797.844L.176 5.303A.57.57 0 010 4.87a.568.568 0 01.176-.434"/>
      </svg>
    </a>
    <div class="text-center w-20 text-lg font-bold px-4">
      {{ days[$store.state.day] }}
    </div>
    <a
        class="icon-button white"
        :class="{'invisible': $store.state.day >= 5}"
        @click.prevent="go($store.state.day+1)"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="10.875" height="9.739" class="transform rotate-180">
        <path fill="#0069b9"
              d="M.176 4.436L4.75.159A.58.58 0 015.547 1l-3.56 3.33h8.308a.58.58 0 110 1.16H2.074l3.473 3.247a.58.58 0 01-.797.844L.176 5.303A.57.57 0 010 4.87a.568.568 0 01.176-.434"/>
      </svg>
    </a>
  </div>
</template>

<script>
export default {
  name: 'DayNavigation',
  props: {
    days: {
      type: Array,
      required: true
    }
  },
  data () {
    return {}
  },
  methods: {
    go (day) {
      if (day >= 0 && day <= 5) {
        this.$store.commit('day', day)
      }
    }
  }
}
</script>
