<template>
  <div class="filter-button-options">
    <template v-if="typeof filter.groups !== 'undefined'">
      <ul v-for="group in filter.groups" class="group">
        <li class="text-left">{{ group.label }}</li>
        <li v-for="option in group.results"
            class="option">
          <div
              :class="{'active': option.active}"
              @click.prevent="$store.dispatch('toggleFilter', {key: target, option})"
          >
            {{ option.label }}
            <span></span>
            <div></div>
          </div>
        </li>
      </ul>
    </template>
    <template v-else>
      <ul>
        <li v-for="option in filter.results"
            class="option">
          <div
              :class="{'active': option.active}"
              @click.prevent="$store.dispatch('toggleFilter', {key: target, option})"
          >
            <span>{{ option.label }}</span>
            <div></div>
          </div>
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    filter: {
      type: Object,
      required: true
    },
    target: {
      required: true
    }
  }
}
</script>
