import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import Notifications from 'vt-notifications';

import state from './modules/state';
import mutations from './modules/mutations';
import actions from './modules/actions';
import getters from './modules/getters';

Vue.use(Vuex);
Vue.use(Notifications);

Vue.prototype.$authRetry = true;

const persistedStateKey = 'timetable-vuex';

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {},
  plugins: [
    createPersistedState({
      key: persistedStateKey,
    })],
});
