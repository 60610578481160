import _get from 'lodash/get';
import spacetime from 'spacetime';

export default {
  request(state) {
    state.status = 'loading';
  },
  request_soft(state) {
    state.status = 'loading_soft';
  },
  request_error(state) {
    state.status = 'error';
  },
  request_ready(state) {
    state.status = 'ready';
  },
  auth_token(state, token) {
    state.status = 'auth_success';
    state.auth.token = token;
  },
  auth_impersonating(state, impersonationToken) {
    state.auth.impersonating = impersonationToken;
  },
  auth_user(state, user) {
    state.status = 'auth_success';
    state.auth.user = user;
  },
  auth_error(state) {
    state.status = 'auth_error';
    state.auth.token = null;
    state.auth.user = {};
  },
  auth_enrol(state, lesson) {
    state.auth.user.lessons.push(lesson);
  },
  set_timezone(state) {
    const now = spacetime.now();
    state.localTime = now;
    state.localTimeZone = now.timezone();
  },
  meta(state, meta) {
    if (meta.timetable) {
      state.timetable = meta.timetable;
      if (meta.course) {
        state.course = meta.course;
      }
    }
    state.regions = meta.regions;
    state.timetables = meta.timetables;
    state.banners = meta.banners;
    state.nextTermDate = meta.nextTermDate;
  },
  region(state, region) {
    state.region = region;
  },
  timetable(state, timetable) {
    state.timetable = timetable;
  },
  course(state, course) {
    state.course = course;
  },
  terms(state, terms) {
    state.terms = terms;
    state.term = terms.current;
    state.is_online_region = terms.is_online_region;
  },
  day(state, day) {
    day = parseInt(day);
    state.day = day <= 0 ? 0 : day > 6 ? 6 : day;
  },
  filters(state, filters) {
    state.filters = filters;
  },
  clear_filters(state) {
    const map = option => {
      option.active = false;
      return option;
    };
    for (const [key] of Object.entries(state.filtered)) {
      state.filtered[key] = [];
    }
    for (const [key] of Object.entries(state.filters)) {
      if (typeof state.filters[key].groups !== 'undefined') {
        state.filters[key].groups.forEach((g, index) => {
          state.filters[key].groups[index].results = state.filters[key].groups[index].results.map(
              map);
        });
        continue;
      }
      state.filters[key].results = state.filters[key].results.map(map);
    }
  },
  filter(state, filter) {
    filter.option.active = !filter.option.active;
    if (!filter.option.active) {
      const index = state.filtered[filter.key].indexOf(filter.option.value);
      state.filtered[filter.key].splice(index, 1);
      return;
    }

    if (state.filters[filter.key].type === 'multiple') {
      // Multiple filter, push option onto stack
      return state.filtered[filter.key].push(filter.option.value);
    }

    // Single filter, set other filters to in-active, set first item to option value
    const map = option => {
      if (option.value !== filter.option.value) {
        option.active = false;
      }
      return option;
    };

    if (typeof state.filters[filter.key].groups !== 'undefined') {
      state.filters[filter.key].groups.forEach(group => {
        group.results.map(map);
      });
    } else {
      state.filters[filter.key].results = state.filters[filter.key].results.map(
          map);
    }

    state.filtered[filter.key] = [];
    state.filtered[filter.key].push(filter.option.value);
  },
  lessons_filter(state) {
    let filters = [];
    Object.entries(state.filtered).forEach(filter => {
      if (filter[1].length) {
        filters.push({
          key: state.filters[filter[0]].match,
          options: filter[1],
        });
      }
    });

    if (!filters.length && state.term) {
      state.term.lessons = state?.term?.lessons.map(lesson => {
        lesson.filtered = false;
        return lesson;
      });
      return;
    }

    filters.forEach(filter => {
      state.term.lessons = state?.term?.lessons.map(lesson => {
        let value = filter.key.includes('.')
            ? _get(lesson, filter.key)
            : lesson[filter.key];
        lesson.filtered = !filter.options.includes(value);
        return lesson;
      });
    });
  },
};
