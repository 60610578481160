import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import Timetable from '../views/Timetable';
import Welcome from '../views/Welcome';
import SelectRegion from '../views/SelectRegion.vue';
import SelectTimetable from '../views/SelectTimetable';
import PageNotFound from '../views/404';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Timetable',
    component: Timetable,
    beforeEnter: (to, from, next) => {
      if (store.getters.shouldSelectRegion) {
        next({
          name: 'SelectRegion',
        });
      }
      if (!store.getters.hasCourse) {
        next({
          name: 'Welcome',
        });
      }
      next();
    },
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: Welcome,
    beforeEnter: (to, from, next) => {
      if (store.getters.shouldSelectRegion) {
        next({
          name: 'SelectRegion',
        });
      }
      if (!store.state.timetable.id) {
        next({
          name: 'SelectTimetable',
        });
      }
      next();
    },
  },
  {
    path: '/select-a-timetable',
    name: 'SelectTimetable',
    component: SelectTimetable,
    beforeEnter: (to, from, next) => {
      if (store.getters.shouldSelectRegion) {
        next({
          name: 'SelectRegion',
        });
      }
      next();
    },
  },
  {
    path: '/select-a-region',
    name: 'SelectRegion',
    component: SelectRegion,
  },
  {
    path: '*',
    component: PageNotFound,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
});

export default router;
