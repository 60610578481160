<template>
  <div id="select-timetable" class="lg:py-20 lg:px-8 flex flex-col items-center justify-center">
    <header class="w-full flex flex-col items-center px-8 py-12 lg:px-0 lg:py-0 mb-10 lg:mb-0 bg-blue-light lg:bg-transparent">
      <a @click.prevent="goBack" class="button tight alternate inverted-hover min-width mb-0 lg:mb-12">
        <svg xmlns="http://www.w3.org/2000/svg" width="10.875" height="9.739" class="mr-2">
          <path fill="#FFF"
                d="M.176 4.436L4.75.159A.58.58 0 015.547 1l-3.56 3.33h8.308a.58.58 0 110 1.16H2.074l3.473 3.247a.58.58 0 01-.797.844L.176 5.303A.57.57 0 010 4.87a.568.568 0 01.176-.434"/>
        </svg>
        Go back
      </a>
    </header>
    <h1 class="text-blue text-4xl font-bold mb-12 hidden lg:block">
      Please select a CIS Timetable
    </h1>
    <section class="w-full" v-if="$store.state.timetables.length">
      <header class="px-8 py-12 lg:px-0 lg:py-0 mb-10 lg:mb-0 bg-blue-light lg:bg-transparent">
        <h3 class="text-white lg:text-blue text-2xl font-bold lg:text-3xl lg:font-normal mb-0 lg:mb-5 text-center">
          Choose your timetable
        </h3>
      </header>
      <div class="flex flex-col lg:flex-row px-10 lg:px-0 items-center justify-center -mx-4">
        <template v-for="timetable in $store.state.timetables">
          <a
              class="button tight inverted mx-4 my-4 lg:my-0 w-full lg:w-auto max-w-lg lg:max-w-none"
              @click.prevent="$store.dispatch('setTimetable', {timetable, clearCourse: true})"
          >
            {{ timetable.label }}
          </a>
        </template>
      </div>
    </section>
    <section v-else>
      <h3 class="text-blue text-3xl mb-5 text-center">
        There are currently no timetables available, please check back soon
      </h3>
    </section>
  </div>
</template>

<script>
export default {
  name: 'SelectTimetable',
  methods: {
    goBack () {
      this.$router.push({
        name: 'SelectRegion'
      })
    }
  }
};

</script>
