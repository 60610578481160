<template>
  <nav
      id="filters"
      class="mt-4 lg:pl-4"
      :class="{'opacity-0' : !$store.state.filters}">
    <ul>
      <li class="hidden lg:block">
        <span class="inline-block mt-3 text-blue-lighter text-lg uppercase leading-none font-bold">
          Search
        </span>
      </li>
      <li
          v-for="(filter, key) in $store.state.filters"
          v-if="(typeof filter.results !== 'undefined' && !!filter.results.length) || (typeof filter.groups !== 'undefined' && !!filter.groups.length)"
          :class="{'single': filter.type === 'single'}"
          class="flex mb-8 lg:mb-0 w-full max-w-md lg:max-w-none lg:w-auto lg:block"
      >
        <filter-button :filter="filter" :target="key"></filter-button>
      </li>
      <li class="hidden lg:block">
        <a
            v-if="$store.getters.hasFilters"
            class="inline-block mt-3 font-bold text-blue-lighter text-sm uppercase cursor-pointer hover:text-blue transition duration-300 ease-out"
            @click.prevent="$store.dispatch('clearFilters')"
        >
          Clear all
        </a>
      </li>
<!--      <li class="flex mb-8 lg:mb-0 w-full max-w-md lg:max-w-none lg:w-auto lg:block lg:ml-auto">-->
<!--        <switch-timetable></switch-timetable>-->
<!--      </li>-->
    </ul>
  </nav>
</template>

<script>
import FilterButton from '@/components/filters/FilterButton'
import SwitchTimetable from '@/components/SwitchTimetable'

export default {
  components: {
    FilterButton,
    SwitchTimetable
  }
}
</script>
