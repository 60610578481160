<template>
  <section>
    <header id="banner" class="mb-12 hidden lg:block">
      <div class="flex">
        <main class="flex-1 pt-10 px-4">
          <h1 class="text-3xl font-light text-blue">
            <span class="font-bold">{{ this.$store.state.timetable.label }} Timetable</span>
            <span v-if="this.$store.state.timetable.courses.length > 1">
              | {{ this.$store.state.course.label }}
            </span>
          </h1>
          <term-navigation></term-navigation>
        </main>
        <aside
            class="flex flex-1 justify-end px-4 pt-8 pb-4"
            v-if="$store.getters.getBanner"
        >
          <a
              :href="$store.getters.getBanner.url"
              :title="$store.getters.getBanner.label"
              class="inline-block hvr-buzz-out"
              target="_blank"
          >
            <img :src="$store.getters.getBanner.image" :alt="$store.getters.getBanner.label"/>
          </a>
        </aside>
      </div>
      <filters></filters>
    </header>
    <header id="banner-small" class="block lg:hidden">
      <filter-menu></filter-menu>
      <div
          class="flex justify-between text-blue-light text-1lg font-light justify-center font-bold p-6"
          v-if="$store.getters.hasRegion"
      >
        <div>
          <span class="font-bold text-blue inline-flex items-center mr-2">
            {{ $store.state.region.label }}
          </span>
          <span class="text-lg text-blue">{{ $store.state.region.gmt_offset_full_formatted }}</span>
        </div>
        <div class="text-right">
          <p class="text-lg" v-if="$store.getters.showLocalTimezone">
            Times are shown in local time {{ $store.getters.localTimeZoneForHumans }}
          </p>
        </div>

      </div>
    </header>
    <div id="header-controls">
      <div>
        <a @click.prevent="goBack">Change level</a>
      </div>
      <div class="text-center">
        <p>Select a class</p>
      </div>
      <div class="text-right hidden lg:block">
        <p class="text-sm lg:text-lg" style="color:#2b927a;" v-if="$store.getters.showLocalTimezone">
          Times are shown in local time {{ $store.getters.localTimeZoneForHumans }}
        </p>
      </div>
    </div>
    <div id="timetable" class="mb-16">
      <header id="header">
        <div>&nbsp;</div>
        <div v-for="day in days">
          {{ day }}
        </div>
      </header>
      <header id="header-small">
        <day-navigation :days="days"></day-navigation>
      </header>
      <main>
        <div id="timetable-no-results" v-if="!$store.getters.hasLessons">
          <p>There are no lessons for this term yet, please check back soon.</p>
        </div>
        <div class="timetable-row"
             v-for="time in ['morning', 'afternoon', 'evening']">
          <div>
            <img :src="`./app/assets/icons/${time}.svg`" :alt="time"/>
            <p>{{ time }}</p>
          </div>
          <div class="timetable-lesson" v-for="(day, index) in days">
            <template
                v-if="$store.state.term"
                v-for="lesson in $store.getters.getLessonsByDayTime(index, time)"
            >
              <lesson :data="lesson" :key="lesson.id"></lesson>
            </template>
          </div>
          <div class="timetable-lessons-small">
            <template
                v-if="$store.state.term"
                v-for="lesson in $store.getters.getLessonsByDayTime($store.state.day, time)"
            >
              <lesson :data="lesson" :key="`${lesson.id}_small`"></lesson>
            </template>
          </div>
        </div>
      </main>
      <footer>
        <p v-if="$store.getters.isLoggedIn">
          Hello, <strong>{{ $store.state.auth.user.name }}</strong>
        </p>
        <div class="leading-none text-blue-icon">
          <img src="@/assets/icons/online-course.svg" alt="Online course"/>
          Online course
        </div>
      </footer>
    </div>
  </section>
</template>

<script>
import TermNavigation from '@/components/TermNavigation';
import DayNavigation from '@/components/DayNavigation';
import Filters from '@/components/filters/Filters';
import FilterMenu from '@/components/filters/FilterMenu';
import Lesson from '@/components/Lesson';

export default {
  name: 'Timetable',
  components: {
    TermNavigation,
    DayNavigation,
    Filters,
    FilterMenu,
    Lesson,
  },
  mounted() {
    this.$store.dispatch('getFilters');
    this.$store.dispatch('getTerms').catch(() => {
      this.$router.push({
        name: 'SelectTimetable',
      });
    });
  },
  data() {
    return {
      days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    };
  },
  methods: {
    goBack() {
      if (this.$store.state.timetable.courses.length === 1) {
        return this.$router.push({
          name: 'SelectTimetable',
        });
      }
      this.$router.push({
        name: 'Welcome',
      });
    },
  },
};
</script>
