<template>
  <div class="w-full">
    <a
        class="button filter-button"
        :class="{'active': active(filter), 'open': open}"
        @click.prevent="open = !open"
    >
      {{ filter.label }}
      <filter-button-options :filter="filter" :target="target"></filter-button-options>
    </a>
    <filter-button-options :filter="filter" :target="target"></filter-button-options>
  </div>
</template>

<script>
import FilterButtonOptions from '@/components/filters/FilterButtonOptions'

export default {
  name: 'FilterButton',
  components: {
    FilterButtonOptions
  },
  props: {
    filter: {
      type: Object,
      required: true
    },
    target: {
      required: true
    }
  },
  data () {
    return {
      open: false
    }
  },
  methods: {
    active (filter) {
      if (typeof filter.groups !== 'undefined') {
        let filtered = false
        filter.groups.forEach(options => {
          if (options.results.filter(option => option.active).length) {
            filtered = true
          }
        })
        return filtered
      }
      return filter.results.filter(option => option.active).length
    }
  }
}
</script>
