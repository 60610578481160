<template>
  <div class="modal" :class="[{open}, classes]">
    <div class="modal-underlay" @click="close"></div>
    <div class="container">
      <a class="modal-close" @click.prevent="close">
        <svg xmlns="http://www.w3.org/2000/svg" width="13.034" height="13.034">
          <path fill="none" stroke="#FFF" stroke-width="1.6" stroke-linecap="round"
                d="M.8.8l11.434 11.433M12.234.8L.8 12.233"/>
        </svg>
      </a>
    </div>
    <div class="modal-body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    opened: {
      type: Boolean,
      required: false,
      default: false
    },
    classes: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      open: this.opened
    }
  },
  methods: {
    openMe() {
      this.open = true
    },
    close () {
      this.open = false
    }
  }
}
</script>
