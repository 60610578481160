<template>
  <div id="welcome" class="lg:py-20 lg:px-8 flex flex-col items-center justify-center w-full">
    <header class="w-full flex flex-col items-center px-8 py-12 lg:px-0 lg:py-0 mb-10 lg:mb-0 bg-blue-light lg:bg-transparent">
      <h1 class="text-white lg:text-blue text-center text-3xl font-bold mb-4 lg:mb-6">
        {{ $store.state.timetable.label }} Timetable
      </h1>
      <a @click.prevent="goBack" class="button tight alternate inverted-hover min-width mb-0 lg:mb-12">
        <svg xmlns="http://www.w3.org/2000/svg" width="10.875" height="9.739" class="mr-2">
          <path fill="#FFF"
                d="M.176 4.436L4.75.159A.58.58 0 015.547 1l-3.56 3.33h8.308a.58.58 0 110 1.16H2.074l3.473 3.247a.58.58 0 01-.797.844L.176 5.303A.57.57 0 010 4.87a.568.568 0 01.176-.434"/>
        </svg>
        Go back
      </a>
    </header>
    <div class="px-8 lg:px-0 text-center">
      <h2 class="text-blue-sky font-bold text-xl lg:text-2xl" v-if="termStartDate">
        Next term starting {{ termStartDate }}
      </h2>
      <p class="text-blue-sky text-xl lg:text-2xl">
        New courses starting every 5 weeks
      </p>
      <section class="mt-12">
        <h3 class="text-blue text-xl lg:text-1xl mb-5 text-center">
          Choose your level
        </h3>
        <div class="flex flex-col lg:flex-row items-center justify-center -mx-4">
          <template v-for="course in $store.state.timetable.courses">
            <a
                class="button inverted mx-4 my-4 lg:my-0 w-full lg:w-auto max-w-lg lg:max-w-none"
                @click.prevent="$store.dispatch('setCourse', {course, redirect: true})"
            >
              {{ course.label }}
            </a>
          </template>
        </div>
      </section>
      <section class="flex flex-col items-center mt-16">
        <h3 class="text-blue text-lg lg:text-2lg mb-5">
          Not sure of your level?
        </h3>
        <a href="https://italianstudies.com.au/take-a-test" class="button tight alternate min-width" target="_blank">
          Take a test
        </a>
      </section>
      <section class="flex flex-col items-center mt-16">
        <h3 class="text-blue text-lg lg:text-2lg">
          Or call us for a 10min phone assessment
        </h3>
        <h3 class="text-blue text-lg lg:text-1lg mb-5">
          (between 9-5pm AEST)
        </h3>
        <a href="tel:0393479144" class="button tight alternate min-width">
          Call
        </a>
      </section>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  computed: {
    termStartDate () {
      if (!this.$store.state.nextTermDate) {
        return false
      }
      return moment.utc(this.$store.state.nextTermDate).format('Do MMMM YYYY')
    }
  },
  methods: {
    goBack() {
      this.$router.push({
        name: 'SelectTimetable'
      })
    }
  }
}
</script>
