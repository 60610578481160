<template>
  <div
      class="lesson"
      :class="{'filtered': typeof data.filtered !== 'undefined' && data.filtered}"
  >
    <modal ref="lessonDetails">
      <section class="lesson">
        <header class="flex flex-col lg:flex-row lg:justify-between">
          <div class="lg:pr-4">
            <h2 class="text-blue-dark text-xl lg:text-2xl flex items-start mb-3">
              <span class="font-bold">
                {{ data.display_label }}
              </span>
              <span class="inline-block mx-4 border-r border-white h-5 mt-3"></span>
              <span>Every {{ data.day_name }} {{ data.display_time }}</span>
            </h2>
            <h3 class="text-white text-base lg:text-lg">
              {{ $store.state.term.label }}
              <span class="inline-block mx-4 border-r border-white h-4 mt-1"></span>
              {{ $store.state.term.date }}
            </h3>
          </div>
          <div class="flex items-center lg:pl-4 mt-4 lg:mt-0"
               v-if="!enrolled && !$store.state.term.has_ended && !data.has_ended && !data.is_full">
            <a class="button tight dark small uppercase whitespace-no-wrap hvr-push"
               @click.prevent="confirmEnrol">
              Enrol now
            </a>
          </div>
        </header>
        <aside class="lesson-alert" v-if="enrolled">
          You {{ $store.state.term.has_ended ? 'were' : 'are' }} enrolled in this class
        </aside>
        <aside class="lesson-alert" v-if="$store.state.term.has_ended">
          Classes have ended for this term
        </aside>
        <aside class="lesson-alert" v-if="!data.has_started">
          This class starts on {{ data.display_start_date }}
        </aside>
        <aside class="lesson-alert" v-if="data.has_ended">
          This class has ended for this term
        </aside>
        <aside class="lesson-alert" v-if="data.is_full">
          This class is full
        </aside>
        <main>
          <div class="flex flex-col lg:flex-row justify-between mb-6">
            <div class="w-full lg:w-2/3 mb-6 lg:mb-0">
              <h4 class="block text-blue-dark font-bold border-b border-blue-lighter pb-2 w-full mb-3">
                What we cover
              </h4>
              <div class="pr-8 text-blue-dark" v-html="data.details"></div>
            </div>
            <div class="w-full lg:w-1/3">
              <h4 class="block text-blue-dark font-bold border-b border-blue-lighter pb-2 w-full mb-3">
                Teacher
              </h4>
              <p class="flex justify-between">
                {{ data.teacher.name }}
                <img
                    v-if="data.teacher.thumbnail"
                    class="w-1/4 lg:w-1/2 ml-4"
                    :src="data.teacher.thumbnail"
                    :alt="data.teacher.name"
                />
              </p>
            </div>
          </div>
          <div class="flex-1 mb-6">
            <h4 class="block text-blue-dark font-bold border-b border-blue-lighter pb-2 w-full mb-3">
              Course Details
            </h4>
            <div class="lg:pr-8">
              <p>{{ data.course_type.category.label }}</p>
              <p>{{ data.course_type.label }}</p>
              <p>{{ data.course_type.description }}</p>
              <p v-if="data.start_date">Starts on: {{ data.display_start_date }}</p>
              <p v-if="data.stop_date">Ends on: {{ data.display_stop_date }}</p>
            </div>
          </div>
          <div class="flex-1 mb-6">
            <h4 class="block text-blue-dark font-bold border-b border-blue-lighter pb-2 w-full mb-3">
              Location
            </h4>
            <div v-if="data.is_online">
              <p>
                <strong class="font-bold">Online</strong>
                <span v-if="data.location.region">
                  - {{ data.location.region.gmt_offset_full_formatted }}
                </span>
              </p>
            </div>
            <div v-else>
              <p v-if="data.location.label && data.location.label !== data.location.address_line_1">
                {{ data.location.label }}
              </p>
              <p v-html="data.location.address"></p>
            </div>
          </div>
          <div class="flex-1 mb-6">
            <h4 class="block text-blue-dark font-bold border-b border-blue-lighter pb-2 w-full mb-3">
              Dates & Times
            </h4>
            <div>
                <p>Every {{ data.day_name }}, {{ $store.state.term.date }}</p>
                <p>{{ data.display_time }}<span v-if="data.display_timezone"> - {{ data.display_timezone }}</span></p>
            </div>
          </div>
          <div class="mb-6" v-if="additionalLessons.length">
            <h4 class="block text-blue-dark font-bold border-b border-blue-lighter pb-2 w-full mb-3">
              Additional Included Classes
            </h4>
            <p v-for="lesson in additionalLessons">
              {{ lesson.display_label }} - Every {{ lesson.day_name }} {{ lesson.display_time }}
            </p>
          </div>
          <div>
            <h4 class="block text-blue-dark font-bold border-b border-blue-lighter pb-2 w-full mb-3">
              Fees
            </h4>
            <p class="mb-2">
              <template v-if="data.fee_description && data.fee_description !== ''">
                {{ data.fee_description }} {{ data.display_cost }}
              </template>
              <template v-else>
                {{ data.display_duration }} a week for
                <template v-if="data.lesson_term_duration && data.lesson_term_duration !== ''">
                  {{ data.lesson_term_duration }}
                </template>
                <template v-else>
                  {{ $store.state.term.display_duration }}
                </template>
                weeks: {{ data.display_cost }}
              </template>
            </p>
            <div class="text-blue-dark" v-html="data.fee_details"></div>
          </div>
          <div class="mt-6" v-if="data.resources.length">
            <h4 class="block text-blue-dark font-bold border-b border-blue-lighter pb-2 w-full mb-3">
              Resources you will need for this class
            </h4>
            <div class="flex flex-col lg:flex-row lg:-mx-10">
              <div
                  v-for="resource in data.resources"
                  class="flex-1 lg:px-10 pt-2 overflow-hidden"
              >
                <a
                    class="flex flex-col h-full hvr-push text-center"
                    :href="resource.url"
                    target="_blank">
                  <img
                      class="inline-block max-w-full mb-2"
                      :src="resource.thumbnail"
                      :alt="resource.label"
                  />
                  <p class="inline-block text-blue-dark mt-auto">
                    {{ resource.label }}
                  </p>
                </a>
              </div>
            </div>
          </div>
        </main>
        <footer>
          <a
              class="text-white leading-none cursor-pointer hvr-push"
              target="_blank"
              @click.prevent="share = !share"
          >
            <span class="relative" style="top:3px">Share with a friend</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="16.333" height="22.061" class="inline ml-4">
              <path fill="none" stroke="#FFF" stroke-width="1.275" stroke-linecap="round"
                    d="M3.705 8.844H.638v12.579h15.057V8.844h-3.067M8.167.937v12.448M4.168 5.237l3.999-4.3 3.998 4.3"/>
            </svg>
          </a>
          <div class="share" :class="{ 'show': share }">
            <share-it
                :text="data.display_label"
                :url="shareURL"
                :targets="['twitter', 'facebook', 'linkedin', 'whatsapp', 'reddit']"
                icons
                dense
            ></share-it>
            <a class="standalone"
               :href="`mailto:?subject=${data.display_label}&body=${shareURL}`"
               target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 32 32">
                <path fill="none" d="M0 0h32v32H0z"/>
                <path
                    fill="#0069b9"
                    d="M0 4v24h32V4H0zm28.149 2-12.151 9.719L3.851 6h24.298zM2 25.999V7.081l13.998 11.197L29.999 7.081v18.918H2z"/></svg>
            </a>
          </div>
        </footer>
      </section>
    </modal>
    <enrolment
        ref="enrol"
        :key="`enrol_${data.id}`"
        :lesson="data"
        :additional="additionalLessons"
        :share-url="shareURL"
        @enrolled="enrolSuccess"
        v-show="!enrolled && !$store.state.term.has_ended"
    ></enrolment>
    <modal
        ref="enrolConfirm"
        :classes="['small']"
    >
      <div class="p-16">
        <h2 class="text-blue-dark text-xl lg:text-2xl flex justify-center mb-4">
          Thank you {{ $store.state.auth.user.first_name }} for enrolling!
        </h2>
        <div class="text-blue-light text-lg lg:text-xl font-light justify-center text-center font-bold">
          We've sent you a confirmation email at {{ $store.state.auth.user.email }}.
        </div>
      </div>
    </modal>
    <a @click.prevent="open" :class="{ enrolled }">
      <span>{{ data.display_label }}</span>
      <span>{{ start_display }}</span>
      <span v-if="data.is_online" class="online-course">
        <img src="@/assets/icons/online-course.svg" alt="Online course"/>
      </span>
      <span v-if="data.is_full" class="full">Full</span>
    </a>
  </div>
</template>

<style>
.vue-share-it-wrapper {
  display: inline-flex;
}
</style>

<script>
import moment from 'moment';
import Modal from '@/components/Modal';
import Enrolment from '@/components/Enrolment';

export default {
  components: {
    Modal,
    Enrolment,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      share: false,
      additionalLessons: this.data.course_type.is_lesson_inclusive ? this.$store.state.term.lessons.filter(lesson => {
        return lesson.id !== this.data.id && lesson.course_type_id === this.data.course_type_id;
      }) : [],
    };
  },
  mounted() {
    if (this.$store.$urlSearchParams.has('lesson')
        && this.$store.state.term.id === this.data.term_id
        && parseInt(this.$store.$urlSearchParams.get('lesson')) === this.data.id) {
      this.$store.dispatch('setDay', this.data.day - 1);
      this.open();
    }
  },
  methods: {
    open() {
      this.$refs.lessonDetails.open = true;
    },
    confirmEnrol() {
      this.$refs.enrol.$emit('open');
    },
    enrolSuccess() {
      //this.$refs.lessonDetails.open = false
      this.$refs.enrolConfirm.openMe();
    },
  },
  computed: {
    enrolled() {
      return this.$store.getters.enrolled({lesson: this.data, additional: this.additionalLessons || []});
    },
    start() {
      return moment.utc(this.data.start_time, 'HH:mm:SS');
    },
    start_display() {
      if (this.start.minutes() === 0) {
        return this.start.format('ha');
      }
      return this.start.format('h:mma');
    },
    stop() {
      return moment.utc(this.data.stop_time, 'HH:mm:SS');
    },
    shareURL() {
      return `${window.location.origin}?timetable=${this.$store.state.timetable.slug}&course=${this.$store.state.course.slug}&lesson=${this.data.id}`;
    },
  },
};
</script>
