import router from '@/router';
import store from '@/store';
import Vue from 'vue';
import spacetime from 'spacetime';

export default {
  setToken({commit, dispatch}, token) {
    dispatch('setupToken', token);
    commit('auth_token', token);
    dispatch('getUser');
  },
  setupToken({commit, dispatch}, token) {
    window.localStorage.setItem(process.env.VUE_APP_SSO_TOKEN_VAR, token);
    store.$axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    store.$ssoTokenResolver.setSSOToken();
  },
  setTimezone({commit}) {
    commit('set_timezone');
  },
  login({commit, dispatch}, payload) {
    commit('request_soft');
    return new Promise((resolve, reject) => {
      this.$axios({
        url: '/api/login',
        baseURL: process.env.VUE_APP_AUTH_BASE_URL,
        method: 'post',
        data: {
          email: payload.email,
          password: payload.password,
          device_name: 'lingopont-sso',
        },
      }).then(response => {
        commit('auth_token', response.data.token);
        commit('auth_user', response.data.user);
        dispatch('setupToken', response.data.token);

        commit('request_ready');
        resolve(response);
      }).catch(error => {
        console.log(error);
        commit('auth_error');
        dispatch('clearAuth');
        commit('request_error');
        reject(error);
      });
    });
  },
  register({commit, dispatch}, payload) {
    commit('request_soft');
    return new Promise((resolve, reject) => {
      payload.device_name = 'lingopont-sso';
      this.$axios({
        url: '/api/register',
        baseURL: process.env.VUE_APP_AUTH_BASE_URL,
        method: 'post',
        data: payload,
      }).then(response => {
        resolve(response);
      }).catch(error => {
        commit('request_error');
        reject(error);
      });
    });
  },
  async getUser({commit, dispatch}) {
    commit('request');
    this.$axios({url: '/api/user', baseURL: process.env.VUE_APP_AUTH_BASE_URL}).
        then(response => {
          commit('auth_user', response.data);
          commit('request_ready');
        }).
        catch(error => {
          console.log(error);
          commit('auth_error');
          dispatch('clearAuth');
          commit('request_error');
        });
  },
  checkAuth({dispatch, getters}) {
    return new Promise((resolve, reject) => {
      if (getters.isLoggedIn) {
        this.$axios(
            {url: '/api/check', baseURL: process.env.VUE_APP_AUTH_BASE_URL}).
            then(response => {
              resolve(response);
            }).
            catch(error => {
              dispatch('clearAuth');
              reject(error);
            });
      }
      reject();
    });
  },
  async getMeta({commit, state, dispatch}) {
    commit('request');
    const requestedTimetable = this.$urlSearchParams.get('timetable') || null,
        requestedCourse = this.$urlSearchParams.get('course') || null;

    return new Promise((resolve, reject) => {
      this.$axios({
        url: '/api/meta', baseURL: process.env.VUE_APP_BASE_URL,
        params: {
          timetable: requestedTimetable,
          course: requestedCourse,
        },
      }).then(response => {
        commit('meta', response.data);

        if (requestedTimetable) {

          commit('region', {
            gmt_offset: null,
            gmt_offset_formatted: null,
            gmt_offset_full_formatted: null,
            heading_label: 'Showing all locations',
            id: 0,
            label: 'Show all locations',
          });

          const timetable = state.timetables.find(t => {
            return t.slug === requestedTimetable;
          });
          if (timetable) {
            Vue.prototype.$eventBus.$emit('SWITCH_TIMETABLE');
            dispatch('setTimetable', {timetable, clearCourse: true});
          }
        }

        if (requestedCourse) {
          const course = state.timetable.courses.find(c => {
            return c.slug === requestedCourse;
          });
          if (course) {
            dispatch('setCourse', {course: course, redirect: true});
          }
        }

        commit('request_ready');
        resolve(response);
      }).catch(error => {
        reject(error);
        commit('request_error');
      });
    });
  },
  setRegion({commit, getters}, data) {
    commit('region', data.region);
    router.push({
      name: 'SelectTimetable',
    });
  },
  setTimetable({commit, getters, dispatch}, payload) {
    commit('timetable', payload.timetable);

    if (payload.clearCourse) {
      dispatch('setCourse', {course: {id: null}, redirect: false});
    }

    if (payload.timetable.courses.length === 1 &&
        payload.timetable.courses[0].id !== null) {
      dispatch('setCourse',
          {course: payload.timetable.courses[0], redirect: true});
      dispatch('getFilters');
      return dispatch('getTerms');
    }
    dispatch('clearFilters');
    dispatch('getFilters');
    dispatch('getTerms');

    router.push({
      name: getters.hasCourse ? 'Timetable' : 'Welcome',
    });
  },
  setCourse({commit}, data) {
    commit('course', data.course);
    if (data.redirect && router.currentRoute.name !== 'Timetable') {
      router.push({
        name: 'Timetable',
      });
    }
  },
  setDay({commit}, day) {
    commit('day', day);
  },
  async getTerms({commit, getters, dispatch, state}, termID) {
    if (!state.course.id) {
      return;
    }
    commit('request_soft');
    return new Promise(async (resolve, reject) => {
      await this.$axios({
        url: '/api/terms',
        baseURL: process.env.VUE_APP_BASE_URL,
        params: {
          course: state.course.id,
          term: termID || null,
          region: getters.hasRegion ? state.region?.id : null,
          timezone: state.localTimeZone?.name,
        },
      }).then(response => {
        commit('terms', response.data);
        commit('request_ready');
        dispatch('filterLessons');
        resolve(response);
      }).catch(error => {
        commit('terms', {
          previous: null,
          current: null,
          next: null,
        });
        commit('request_error');
        reject(error);
      });
    });
  },
  async getFilters({commit, getters, state}) {

    if (!state.course?.id) {
      return;
    }

    await this.$axios({
      url: '/api/filters',
      baseURL: process.env.VUE_APP_BASE_URL,
      params: {
        course: state.course.id,
        region: getters.hasRegion ? state.region?.id : null,
      },
    }).then(response => {
      commit('filters', response.data);
      commit('request_ready');
    }).catch(error => {
      commit('request_error');
    });
  },
  clearFilters({commit, dispatch}) {
    commit('clear_filters');
    dispatch('filterLessons');
  },
  toggleFilter({commit, dispatch}, filter) {
    commit('filter', filter);
    dispatch('filterLessons');
  },
  filterLessons({commit}) {
    commit('lessons_filter');
  },
  async intent({commit, dispatch, state}, lessonId) {
    commit('request_soft');
    return new Promise((resolve, reject) => {
      this.$axios({
        url: '/api/payment/intent',
        method: 'post',
        baseURL: process.env.VUE_APP_AUTH_BASE_URL,
        data: {
          lesson_id: lessonId,
        },
      }).then(response => {
        commit('request_ready');
        resolve(response);
      }).catch(error => {
        commit('request_error');
        reject(error);
      });
    });
  },
  enrol({commit, dispatch, state}, payload) {
    commit('request_soft');
    return new Promise((resolve, reject) => {
      this.$axios({
        url: '/api/enrol',
        method: 'post',
        baseURL: process.env.VUE_APP_AUTH_BASE_URL,
        data: payload,
      }).then(response => {
        commit('auth_enrol', response.data);
        commit('request_ready');
        resolve(response);
      }).catch(error => {
        commit('request_error');
        reject(error);
      });
    });
  },
  clearAuth({commit}) {
    localStorage.removeItem(process.env.VUE_APP_SSO_TOKEN_VAR);
    delete this.$axios.defaults.headers.common['Authorization'];
    commit('auth_error');
  },
  refreshAuth() {
    // On auth error
    // Fetch fresh token via resolver
    // Attempt to get user again
    // on Fail, set logout, else continue
  },
};
