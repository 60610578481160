<template>
  <div id="filter-menu" :class="{open}">
    <a
        class="flex justify-center items-center px-4 py-6 h-20 bg-blue-light mb-1 cursor-pointer"
        @click.prevent="toggleOrReset"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.861 38.265" class="w-8 mr-4">
        <path fill="none" stroke="#FFF" stroke-width="2.916" d="M0 8.52h13.397M0 29.017h36.396M51.232 29.017h16.629"/>
        <circle fill="none" stroke="#FFF" stroke-width="2.916" cx="20.823" cy="8.884" r="7.426"/>
        <circle fill="none" stroke="#FFF" stroke-width="2.916" cx="43.806" cy="29.381" r="7.426"/>
        <path fill="none" stroke="#FFF" stroke-width="2.916" d="M28.917 8.52h38.944"/>
      </svg>
      <span class="text-white font-bold">
        {{ $store.getters.hasFilters ? 'Reset filters' : 'Filter your search' }}
      </span>
    </a>
    <section id="filter-menu-dropdown" :class="open">
      <main>
        <div class="px-8 pb-8">
          <term-navigation></term-navigation>
          <filters></filters>
        </div>
        <a
            class="fixed bottom-0 flex justify-center mt-auto w-full px-4 py-6 bg-blue-midnight cursor-pointer"
            @click.prevent="open = false"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.239 30.239" class="w-8">
            <path
                fill="#ffffff"
                d="M20.194 3.46c-4.613-4.613-12.121-4.613-16.734 0-4.612 4.614-4.612 12.121 0 16.735 4.108 4.107 10.506 4.547 15.116 1.34.097.459.319.897.676 1.254l6.718 6.718a2.498 2.498 0 003.535 0 2.496 2.496 0 000-3.535l-6.718-6.72a2.5 2.5 0 00-1.253-.674c3.209-4.611 2.769-11.008-1.34-15.118zm-2.121 14.614c-3.444 3.444-9.049 3.444-12.492 0-3.442-3.444-3.442-9.048 0-12.492 3.443-3.443 9.048-3.443 12.492 0 3.444 3.444 3.444 9.048 0 12.492z"/>
          </svg>
        </a>
      </main>
    </section>
  </div>
</template>

<script>
import TermNavigation from '@/components/TermNavigation'
import Filters from '@/components/filters/Filters'

export default {
  components: {
    TermNavigation,
    Filters
  },
  mounted () {
    this.$eventBus.$on('SWITCH_TIMETABLE', () => {
      this.open = false
    })
  },
  data () {
    return {
      open: false
    }
  },
  methods: {
    toggleOrReset () {
      if (this.$store.getters.hasFilters) {
        this.$store.dispatch('clearFilters')
        return this.open = false
      }
      this.open = !this.open
    }
  }
}
</script>
