import spacetime from 'spacetime';

//let localTimeZone = spacetime.now().timezone();

// // Spacetime has a DST bug, this is a temporary fix until the author releases a patch
// if (localTimeZone
//     && localTimeZone.hasDst
//     && localTimeZone.default_offset === 10) {
//   localTimeZone.current.isDST = false;
//   localTimeZone.current.offset = localTimeZone.default_offset;
// }

export default {
  status: null,
  banners: {},
  region: null,
  timetable: {
    id: null,
  },
  course: {
    id: null,
  },
  regions: [],
  timetables: [],
  term: {
    lessons: [],
  },
  terms: {
    previous: null,
    current: null,
    next: null,
  },
  is_online_region: false,
  nextTermDate: null,
  day: 0,
  localTime: spacetime.now(),
  localTimeZone: spacetime.now().timezone(),
  filters: [],
  filtered: {
    level: [],
    course_type: [],
    location: [],
    teacher: [],
  },
  auth: {
    token: localStorage.getItem(process.env.VUE_APP_SSO_TOKEN_VAR) || null,
    impersonating: null,
    user: {
      id: null,
      lessons: [],
    },
  },
};
