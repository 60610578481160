<template>
  <div id="app">
    <div id="impersonating" class="px-6 py-4 bg-yellow" v-if="$store.getters.isImpersonating">
      <p>
        You are impersonating {{ $store.state.auth.user.name }}.
        <a href="/operazioni/impersonate/stop" class="text-blue">Stop impersonating</a>.
      </p>
    </div>
    <s-s-o-token-resolver ref="SSOTokenResolver"></s-s-o-token-resolver>
    <loader></loader>
    <main class="container px-0 lg:px-4">
      <router-view/>
    </main>
    <notifications></notifications>
  </div>
</template>

<script>
import Loader from '@/components/Loader'
import SSOTokenResolver from '@/components/SSOTokenResolver'
import Notifications from '@/components/Notifications'

export default {
  name: 'App',
  components: {
    Loader,
    SSOTokenResolver,
    Notifications
  },
  mounted () {
    // Auth retry and SSO token resolver
    this.$store.$ssoTokenResolver = this.$refs.SSOTokenResolver
    this.$store.$ssoTokenResolver.$on('SSO_TOKEN', (token) => {
      this.$store.dispatch('setToken', token)
    })
    this.$store.commit('auth_impersonating', false)
    this.$store.$ssoTokenResolver.$on('SSO_IS_IMPERSONATING', (impersonationToken) => {
      this.$store.commit('auth_impersonating', impersonationToken)
    })

    // Refresh user data
    if (this.$store.getters.isLoggedIn) {
      this.$store.dispatch('setupToken', this.$store.state.auth.token)
      this.$store.dispatch('getUser')
    }

    this.$store.$axios.interceptors.response.use(response => {
          return response
        },
        error => {
          if (!error) {
            return
          }
          if (error.response.status === 401 && this.$store.getters.hasToken && this.$authRetry) {
            this.$store.$ssoTokenResolver.refresh()
            this.$authRetry = false
          }
          return Promise.reject(error)
        })

    // Redirect to "select timetable"
    // this.$router.push({
    //   name: 'SelectTimetable'
    // })
  }
}
</script>
