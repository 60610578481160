<template>
  <div id="select-region" class="lg:py-20 lg:px-8 flex flex-col items-center justify-center">
    <h1 class="text-blue text-4xl font-bold mb-12 hidden lg:block">
      Welcome to the CIS Timetable
    </h1>
    <section class="w-full" v-if="$store.state.regions.length">
      <header class="px-8 py-12 lg:px-0 lg:py-0 mb-10 lg:mb-0 bg-blue-light lg:bg-transparent">
        <h3 class="text-white lg:text-blue text-2xl font-bold lg:text-3xl lg:font-normal mb-0 lg:mb-5 text-center">
          Please select your class location
        </h3>
      </header>
      <div class="flex flex-col lg:flex-row px-10 lg:px-0 items-center justify-center -mx-4">
        <template v-for="region in $store.state.regions">
          <a
              class="button tight inverted mx-4 my-4 lg:my-0 w-full lg:w-auto max-w-lg lg:max-w-none"
              @click.prevent="$store.dispatch('setRegion', {region})"
          >
            {{ region.label }}
          </a>
        </template>
      </div>
    </section>
    <section v-else>
      <h3 class="text-blue text-3xl mb-5 text-center">
        Oops, we couldn't find any regions, please continue to
        <a @click.prevent="goToSelectTimetable">choose a timetable</a>.
      </h3>
    </section>
  </div>
</template>

<script>
export default {
  name: 'SelectRegion',
  methods: {
    goToSelectTimetable() {
      this.$router.push({
        name: 'SelectTimetable',
      });
    },
  },
};
</script>
